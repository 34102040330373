import { FC } from "react";
import secondBackgroundImage from "../../assets/images/first-block-second.png";
import { MemeCoinComponent, Images } from "../meme-coin-block";
import firstImage from "../../assets/images/Chat-GPT-1.jpeg";
import secondImage from "../../assets/images/Chat-GPT-2.jpeg";
import thirdImage from "../../assets/images/Chat-GPT-3.jpeg";
import fourthImage from "../../assets/images/Chat-GPT-4.jpeg";
import fifthImage from "../../assets/images/Chat-GPT-5.jpeg";
import sixthImage from "../../assets/images/Chatp-GPT-6.jpeg";
import { MediaButton } from "../media-button";
import { TelegramIcon } from "../icons/telegram-icon";
import { TwitterIcon } from "../icons/twitter-icon";

const images: Images[] = [
  { value: firstImage },
  { value: secondImage },
  { value: thirdImage },
  { value: fourthImage },
  { value: fifthImage },
  { value: sixthImage },
];

export const FirstBlockComponent: FC = () => {
  return (
    <main
      className="w-screen h-screen flex flex-col justify-center items-center "
      style={{
        backgroundImage: `url(${secondBackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="w-full  max-w-7xl p-4 flex flex-col justify-center  items-center ">
          <MemeCoinComponent data={images} />
        </div>
      </div>

      <div className="w-full flex flex-row items-center  justify-end     ">
        <div className="flex flex-row  self-end justify-self-end gap-2">
          <MediaButton
            background="#FEDC34"
            text="Telegram"
            icon={<TelegramIcon />}
          />
          <MediaButton
            background="#FEDC34"
            text="Twitter"
            icon={<TwitterIcon />}
          />
        </div>
      </div>
    </main>
  );
};
