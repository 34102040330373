import { Outlet } from "react-router-dom";
import { Header } from "../components/header";

const MainLayout = () => {
  return (
    <div className=" relative">
      <Header />
      <Outlet />
    </div>
  );
};

export { MainLayout };
