import { FC } from "react";

import leftArrow from "../../assets/logos/left-arrow.png";
import rightArrow from "../../assets/logos/right-arrow.png";

interface Props {
  width: string;
  image?: string;
  onNextPress?: () => void;
  onPreviousPress?: () => void;
}

export const ItemComponent: FC<Props> = ({
  width,
  image,
  onNextPress,
  onPreviousPress,
}) => {
  return (
    <div style={{ width: width }}>
      <div className="w-full flex flex-row items-center justify-between">
        <button onClick={onPreviousPress}>
          <img src={leftArrow} alt="left-arrow" />
        </button>
        <div className="w-[100px] h-[100px] border-2 border-[#4A92B5] rounded-lg p-4 bg-white">
          <img className="w-full h-full" src={image} alt="imageItem" />
        </div>
        <button onClick={onNextPress}>
          <img src={rightArrow} alt="right-arrow" />
        </button>
      </div>
    </div>
  );
};
