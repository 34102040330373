import { FC } from "react";
import backgroundImage from "../../assets/images/space-shuttle.webp";
import sittingCat from "../../assets/images/sitting-cat.png";

import { MilestoneMagicComponent, Milestones } from "../milestone-magic";

const milestones: Milestones[] = [
  {
    title: "Phase 1: Create a Fashion Cat:",
    value:
      "Design your very own Fashion Cat NFT. Let your creativity run wild and save the image.",
  },
  {
    title: "Phase 2:  Post on Twitter",
    value:
      "Share your Fashion Cat on Twitter and tag 3 friends with the message: 'Check out my Fashion Cat!'",
  },
  {
    title: "Phase 3: Wait for the Revea",
    value:
      "We will reveal a new NFT we’ve generated every 24 hours. Stay tuned!",
  },
  {
    title: "Phase 4: Match and Win",
    value:
      "If your Jordan Cat matches the one we generated, your token balance will double. It’s that simple and exciting!",
  },
];

export const FourthBlockComponent: FC = () => {
  return (
    <main
      className="w-screen flex flex-col"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <div className="w-full flex flex-col p-3  md:px-4 py-6 md:py-14 gap-10 md:gap-10">
        <section className="w-full  flex flex-col items-center gap-5  md:flex-row md:items-center md:justify-between">
          <div className="flex w-full md:w-[20%] flex-row justify-center md:justify-end items-start">
            <img
              className="h-[150px] md:h-[230px]"
              src={sittingCat}
              alt="sitting-cat"
            />
          </div>
          <div className="flex w-[50%] flex-col justify-center items-center gap-8 ">
            <main className="flex flex-row w-full justify-center items-center">
              <span className="subline-yellow text-center text-xl md:text-3xl text-white">
                How to Win with Jordan Cats
              </span>
            </main>
            <span
              className="text-sm md:text-lg text-center text-white"
              style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)" }}
            >
              Every 24 hours, we offer a thrilling opportunity to earn big with
              your creativity! Follow these simple steps to participate:
            </span>
          </div>
          <div className="w-[20%] flex flex-row justify-center md:justify-end items-center gap-2">
            <span
              className="subline-yellow text-xl md:text-3xl text-white"
              style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)" }}
            >
              Jordan
            </span>
            <span
              className="subline-yellow text-xl md:text-3xl text-white"
              style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)" }}
            >
              Cats
            </span>
            <span
              className="subline-yellow text-xl md:text-3xl text-white"
              style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)" }}
            >
              Win
            </span>
          </div>
        </section>

        <div className="w-full flex flex-col md:flex-row justify-between items-center gap-6 md:gap-0">
          <div className="w-full md:w-[50%] flex flex-col gap-4 md:gap-[18px]">
            {/* <div className="w-full flex flex-col md:flex-row justify-between items-center gap-2 md:gap-0">
              <span
                className="text-lg md:text-xl w-full md:w-[50%] text-[#68C0FF]"
                style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)" }}
              >
                How to Win with Jordan Cats
              </span>
              <div className="w-full md:w-[75%] h-1 bg-[#68C0FF]"></div>
            </div> */}

            <div className="w-full"></div>
          </div>
        </div>
        <div className="w-full flex items-center justify-center">
          <div className="w-full md:w-[60%]">
            <MilestoneMagicComponent
              headline=" Create Your Fashion Cat Now!"
              data={milestones}
              isGradientEnabled
              isBorderEnabled
              titleColor="#FEDB35"
              valueColor="white"
              headLineColor="white"
            />
          </div>
        </div>
      </div>
    </main>
  );
};
