import { FC, useEffect } from "react";
import { GenerateButton } from "../generate-button";
import { ItemComponent } from "../item-component";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
  nextItem,
  previousItem,
  randomItem,
  resetItem,
} from "../../redux/slices/slice";

import catImage from "../../assets/generated-cat/generated-cat.png";
import catImageReserve from "../../assets/generated-cat/generated-cat-reserve.png";
import html2canvas from "html2canvas";

export const GeneratorComponent: FC = () => {
  const dispatch = useAppDispatch();
  const backgroundImage = useAppSelector(
    (state) =>
      state.imageReducer.backgrounds[
        state.imageReducer.currentIndexes.backgrounds
      ]
  );
  const shirtImage = useAppSelector(
    (state) =>
      state.imageReducer.shirts[state.imageReducer.currentIndexes.shirts]
  );

  const capImage = useAppSelector(
    (state) => state.imageReducer.caps[state.imageReducer.currentIndexes.caps]
  );

  const chainImage = useAppSelector(
    (state) =>
      state.imageReducer.chains[state.imageReducer.currentIndexes.chains]
  );

  // const eyeImage = useAppSelector(
  //   (state) => state.imageReducer.eyes[state.imageReducer.currentIndexes.eyes]
  // );

  const bootsImage = useAppSelector(
    (state) => state.imageReducer.boots[state.imageReducer.currentIndexes.boots]
  );

  const shirtIndex = useAppSelector(
    (state) => state.imageReducer.currentIndexes.shirts
  );

  const shortIndex = useAppSelector(
    (state) => state.imageReducer.currentIndexes.shorts
  );
  console.log(shortIndex);

  const shortsImage = useAppSelector(
    (state) =>
      state.imageReducer.shorts[state.imageReducer.currentIndexes.shorts]
  );

  const bootIndex = useAppSelector(
    (state) => state.imageReducer.currentIndexes.boots
  );

  const capIndex = useAppSelector(
    (state) => state.imageReducer.currentIndexes.caps
  );

  const saveImage = async () => {
    const element = document.getElementById("capture");
    if (element instanceof HTMLElement) {
      const canvas = await html2canvas(element);
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = "customized-cat.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Failed to find the element to capture");
    }
  };

  const getShirtImage = (index: number) => {
    switch (index) {
      case 0:
        return "absolute w-[34%] top-[38%] left-[34%] h-[31%]  md:top-[36.5%] md:w-[36.2%] md:left-[33%] md:h-[31.2%]";
      case 1:
        return "absolute w-[38%] top-[38%] h-[31%] left-[31%]  md:top-[36%] md:w-[40%] md:left-[30%] md:h-[31.4%]";
      case 2:
        return "absolute w-[38%] top-[38%] h-[31%] left-[31%]  md:top-[36%] md:w-[40%] md:left-[30%] md:h-[31.4%]";
      default:
        return "absolute w-[34%] top-[39%] left-[34%] h-[31%]  md:top-[36.5%] md:w-[36.2%] md:left-[33%] md:h-[31.2%]";
    }
  };

  const getShirtImageClassname = getShirtImage(shirtIndex);
  return (
    <div className="w-full h-screen flex flex-col lg:flex-row bg-[#0D1927]">
      <div className="flex-1 lg:w-3/5 flex flex-col p-4 md:p-8 justify-between items-center">
        <div className="w-full flex flex-row  justify-center">
          <span className="customization">Customization</span>
        </div>
        <div className="flex flex-col items-center w-full gap-4 md:flex-row md:flex-wrap md:gap-4">
          <ItemComponent
            width="31%"
            image={backgroundImage}
            onNextPress={() => dispatch(nextItem("backgrounds"))}
            onPreviousPress={() => dispatch(previousItem("backgrounds"))}
          />
          <ItemComponent
            width="31%"
            image={bootsImage}
            onNextPress={() => dispatch(nextItem("boots"))}
            onPreviousPress={() => dispatch(previousItem("boots"))}
          />

          <ItemComponent
            width="31%"
            image={shirtImage}
            onNextPress={() => dispatch(nextItem("shirts"))}
            onPreviousPress={() => dispatch(previousItem("shirts"))}
          />
          <ItemComponent
            width="31%"
            image={capImage}
            onNextPress={() => dispatch(nextItem("caps"))}
            onPreviousPress={() => dispatch(previousItem("caps"))}
          />
          <ItemComponent
            width="31%"
            image={shortsImage}
            onNextPress={() => dispatch(nextItem("shorts"))}
            onPreviousPress={() => dispatch(previousItem("shorts"))}
          />
        </div>
        <div className="w-full flex flex-row items-center justify-between mt-4 ld:mt-0 ">
          <GenerateButton
            width="30%"
            topColor="#58EF58"
            bottomColor="#11E710"
            text="Random"
            borderColor="#046E06"
            onPress={() => dispatch(randomItem())}
          />
          <GenerateButton
            width="30%"
            topColor="#60CAFC"
            bottomColor="#1BB5FC"
            text="Reset"
            borderColor="#055173"
            onPress={() => dispatch(resetItem())}
          />
          <GenerateButton
            width="30%"
            topColor="#FCF760"
            bottomColor="#FCF31A"
            text="Save"
            borderColor="#78740A"
            onPress={saveImage}
          />
        </div>
      </div>
      <div id="capture" className="w-full md:w-[40%] h-full">
        <div
          className="w-full h-full flex justify-end items-center flex-col relative px-10 pt-10"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <img
            className="w-[50%] h-[92%]"
            src={catImageReserve}
            alt="generated-cat"
          />
          {capIndex !== 3 && (
            <img
              className="absolute top-[14.7%] md:top-[14%] w-[31%] md:w-[33%] left-[30%]  md:left-[29%]"
              src={capImage}
              alt="caps"
            />
          )}

          {bootIndex !== 3 && (
            <img
              src={bootsImage}
              alt="boots"
              className=" absolute bottom-0 left-[28.6%] w-[36.4%] h-[17.6%]"
            />
          )}

          {shirtIndex !== 3 && (
            <img
              src={shirtImage}
              alt="shirts"
              className={getShirtImageClassname}
            />
          )}

          {shortIndex !== 3 && (
            <img
              src={shortsImage}
              className="absolute w-[30%] left-[33.5%] bottom-[18.5%] h-[19%] md:left-[32.6%] md:w-[32%] md:bottom-[19%] md:h-[19.2%] "
              alt="shorts"
            />
          )}
        </div>
      </div>
    </div>
  );
};
