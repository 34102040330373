import { FC } from "react";

export interface Milestones {
  title: string;
  value: string;
}

interface Props {
  data: Milestones[];
  headline: string;
  isGradientEnabled?: boolean;
  isBorderEnabled?: boolean;
  titleColor: string;
  valueColor?: string;
  isHeadlineStyling?: boolean;
  isWrapped?: boolean;
  headLineColor: string;
}

export const MilestoneMagicComponent: FC<Props> = ({
  data,
  headline,
  isBorderEnabled,
  isGradientEnabled,
  titleColor,
  valueColor,
  isHeadlineStyling,
  isWrapped,
  headLineColor,
}) => {
  return (
    <div
      className={`flex w-full p-4 md:p-7 justify-center items-center ${
        isBorderEnabled ? "border-white border-2" : ""
      }`}
    >
      <div className="w-full flex flex-col gap-4">
        <div
          className="w-full flex justify-center items-center p-3"
          style={
            isGradientEnabled
              ? {
                  backgroundImage: "linear-gradient(to left, #311C8F, #8C3AC1)",
                }
              : {}
          }
        >
          <span
            className={`${
              isHeadlineStyling ? "headline" : "subline"
            } text-2xl md:text-5xl`}
            style={{
              textShadow: "5px 5px 11px rgba(0, 0, 0, 1)",
              color: headLineColor,
            }}
          >
            {headline}
          </span>
        </div>
        <div
          className={`w-full ${
            isWrapped
              ? "py-4 flex md:flex-wrap justify-center items-center"
              : "flex flex-col"
          } gap-4 md:gap-8`}
          style={{
            backgroundColor: isWrapped
              ? "rgba(255, 255, 255, 0.2)"
              : "transparent",
          }}
        >
          {data.map((item, index) => (
            <div className="flex flex-col gap-1 md:gap-4" key={index}>
              <span
                className={` text-xl md:text-[40px]`}
                style={{
                  color: titleColor,
                  textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)",
                }}
              >
                {item.title}
              </span>
              <span
                className={`text-sm md:text-lg`}
                style={{
                  color: valueColor,
                  textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)",
                }}
              >
                {item.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
