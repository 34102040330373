import { FC } from "react";
import backgroundImage from "../../assets/images/Stars-In-Space.webp";
import rocketCat from "../../assets/images/space-cat.png";

export const SecondBlockComponent: FC = () => {
  return (
    <main
      className="w-screen h-screen flex justify-center items-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="w-full h-full flex flex-col md:flex-row p-4 md:p-14 justify-between items-center">
        <div className="w-full md:w-1/2 flex flex-col h-full gap-4">
          {/* <div className="flex items-center gap-2">
            <div className="w-1/4 h-1 bg-[#68C0FF]"></div>
            <span
              className="text-sm md:text-xl text-[#68C0FF]"
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              About Jordan Cats
            </span>
          </div> */}
          <div>
            <span
              className="text-2xl md:text-6xl subline-yellow "
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              Jordan cats mission
            </span>
          </div>
          <div>
            <span
              className=" text-xs md:text-lg text-white"
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              Join the revolution of meme coins with Jordan Cats! Dive into a
              world where creativity meets cryptocurrency on the Solana network.
              Mint your unique NFTs and double your coins every 24 hours! Are
              you ready to create the coolest fashion cats and become a part of
              an energetic and engaging community? Jordan Cats is not just
              another meme coin; it’s the first interactive meme coin designed
              to build an active, thriving community. Inspired by the agility
              and style of cats and the legendary spirit of Michael Jordan, this
              project blends fun and innovation to offer an exciting crypto
              experience.
            </span>
          </div>
          {/* <div>
            <span
              className="text-white"
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              Contract Address
            </span>
          </div> */}
          {/* <div>
            <span
              className="text-white"
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              21NN3SD3dNo2MW8HBwGeYSKazTEYndQmVwvrPMiz1nJP
            </span>
          </div> */}
        </div>
        <div className="w-full md:w-1/2 flex justify-center md:justify-end">
          <img className="max-w-full h-auto" src={rocketCat} alt="rocket cat" />
        </div>
      </div>
    </main>
  );
};
