import { FC } from "react";

export interface Images {
  value: string;
}

interface Props {
  data: Images[];
}

export const MemeCoinComponent: FC<Props> = ({ data }) => {
  return (
    <div className="w-full h-[370px] justify-between flex flex-col items-center gap-14 p-2">
      <div className="text-center headline">
        <span>Create the First Fashion Cats on Solana!</span>
      </div>

      <div className="text-center subline">
        <span className="text-[30px]">
          Double your coins every 24 hours by participating in our exciting NFT
          game.
        </span>
      </div>
    </div>
  );
};
