import { Route, Routes } from "react-router-dom";
import { MainLayout } from "./layout/MainLayout";
import { HomePage } from "./pages";

function App() {
  return (
    <div className=" w-full flex flex-col ">
      <Routes>
        <Route path={"/"} element={<MainLayout />}>
          <Route index element={<HomePage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
