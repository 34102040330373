import { FC } from "react";

import superHero from "../../assets/images/super-hero-cat.png";
import supermanCat from "../../assets/images/superman-cat.webp";
import kingCat from "../../assets/images/king-cat.webp";
import wizzardCat from "../../assets/images/wizzard-cat.webp";
import starLogo from "../../assets/logos/star-icon.webp";
import bootsLogo from "../../assets/logos/boots-logo-bottom.webp";
import { BirdEyeIcon } from "../icons/bird-eye-icon";
import { DexScreenerIcon } from "../icons/dex-screener-icon";
import { DexToolsIcon } from "../icons/dex-tools-icon";
import { TelegramIcon } from "../icons/telegram-icon";
import { TwitterIcon } from "../icons/twitter-icon";

interface Cats {
  value: string;
}

interface Tables {
  title: string;
  subtitle: string;
  text: string;
  tittleBackgroundColorFrom: string;
  tittleBackgroundColorTo: string;
}

const tables: Tables[] = [
  {
    title: "Audit",
    subtitle: "Official Audit of Pussin Tokens",
    text: "Transparency is key. Understand the risks and rewards before joining. In crypto, due diligence is vital—ignore it at your own risk.",
    tittleBackgroundColorFrom: "#2F1C8F",
    tittleBackgroundColorTo: "#8D3AC2",
  },
  {
    title: "Disclosure",
    subtitle: "Disclosure: your money at risk",
    text: "We believe in complete transparency. Review our disclosures to understand our operations and commitments to our community.",
    tittleBackgroundColorFrom: "#162C4A",
    tittleBackgroundColorTo: "#162C4A",
  },
];

const cats: Cats[] = [
  {
    value: supermanCat,
  },
  {
    value: kingCat,
  },
  {
    value: wizzardCat,
  },
];

export const FifthBlockComponent: FC = () => {
  return (
    <main
      className="w-screen flex flex-col items-center"
      style={{ backgroundColor: "#0D1927" }}
    >
      <div className="w-full flex flex-col p-6 md:p-14 gap-6 md:gap-14">
        <div className="flex flex-col md:flex-row w-full items-center justify-between gap-6 md:gap-0">
          <div className="w-full md:w-[45%] flex flex-col gap-4 md:gap-[18px]">
            {/* <div className="w-full flex flex-col md:flex-row justify-between items-center gap-2 md:gap-0">
              <div className="w-full md:w-[48%] h-1 bg-[#68C0FF]"></div>
              <span
                className="text-lg md:text-xl w-full md:w-[50%] text-[#68C0FF]"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                Collect, Trade, Treasure
              </span>
            </div> */}
            <div className="w-full flex items-center">
              <span
                className="subline-yellow text-4xl md:text-[70px]"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                NFTS
              </span>
            </div>
            <div className="w-full">
              <span
                className="text-sm md:text-base text-white"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                These are just a sample of our amazing NFT collection, that
                features an array of cats in fantastical boots, each with its
                own story and personality. Whether you’re a collector, a trader,
                or just a fan of adorable and quirky designs, there’s a Pussin
                Boots NFT for you. Get your paws on these collectibles and join
                a community that values creativity and fun.
              </span>
            </div>
          </div>
          <div className="w-full md:w-[50%] flex justify-center">
            <img
              className="h-[200px] md:h-[320px]"
              src={superHero}
              alt="superhero"
            />
          </div>
        </div>
        <div className="w-full flex justify-center items-center">
          <button
            className="w-full md:w-[320px] h-[50px] flex justify-center items-center text-[#9BE5FF] text-base"
            style={{
              backgroundImage: "linear-gradient(to left, #3D2196, #8538BE)",
            }}
          >
            GO TO NFT MARKETPLACE NOW!
          </button>
        </div>
        {/* <div className="w-full flex flex-col md:flex-row items-center justify-between gap-6 md:gap-10 px-2 md:px-10">
          {cats.map((item, index) => (
            <div
              key={index}
              className="w-full md:w-[30%] h-[200px] md:h-[550px]"
            >
              <img
                src={item.value}
                className="w-full h-full object-cover"
                alt="cats"
              />
            </div>
          ))}
        </div> */}
        <div className="flex flex-col md:flex-row w-full items-center justify-between gap-6 md:gap-0">
          <div className="w-full md:w-[50%] flex flex-col gap-4 md:gap-[18px]">
            {/* <div className="w-full flex flex-col md:flex-row justify-between items-center gap-2 md:gap-0">
              <img
                className="h-[40px] w-[40px] md:h-[80px] md:w-[80px]"
                src={starLogo}
                alt="star"
              />
              <span
                className="text-lg md:text-xl w-full md:w-[50%] text-[#68C0FF]"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                Join Our Community
              </span>
              <div className="w-full md:w-[62%] h-1 bg-[#68C0FF]"></div>
            </div> */}
            <div className="w-full flex items-center">
              <span
                className="subline-yellow-small text-3xl md:text-[42px]"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                📢 Follow Us for the Latest Updates 📢
              </span>
            </div>
            <div className="w-full gap-4">
              <span
                className="text-sm md:text-lg text-white"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                Stay updated with the latest news, events, and rewards by
                following us on our social media channels. Join the conversation
                and be a part of the Jordan Cats family.
              </span>
              <ul>
                <li>
                  <div className="flex flex-row gap-2">
                    <span className="text-white">🔹</span>
                    <TwitterIcon />
                    <span
                      className="text-sm md:text-lg text-white"
                      style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
                    >
                      {" "}
                      Twitter
                    </span>
                  </div>
                </li>
                <li>
                  <div className="flex flex-row gap-2">
                    <span className="text-white">🔹</span>
                    <TelegramIcon />
                    <span
                      className="text-sm md:text-lg text-white"
                      style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
                    >
                      Telegram
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="w-full flex flex-col md:flex-row items-center justify-between gap-6 md:gap-0">
          {tables.map((item, index) => (
            <div
              key={index}
              className="w-full md:w-[48%] border-2 border-white p-4 flex flex-col justify-center items-center"
            >
              <div className="w-full flex flex-col gap-4 md:gap-6">
                <div
                  className="w-full p-2 flex justify-center items-center"
                  style={{
                    backgroundImage: `linear-gradient(to right, ${item.tittleBackgroundColorFrom}, ${item.tittleBackgroundColorTo})`,
                  }}
                >
                  <span
                    className="text-white text-2xl md:text-5xl"
                    style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
                  >
                    {item.title}
                  </span>
                </div>
                <div className="w-full flex">
                  <span
                    className="text-white text-lg md:text-3xl"
                    style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
                  >
                    {item.subtitle}
                  </span>
                </div>
                <div className="w-full">
                  <span
                    className="text-sm md:text-lg text-slate-300"
                    style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
                  >
                    {item.text}
                  </span>
                </div>
                <div className="w-full flex justify-start">
                  <button
                    className="w-full md:w-[240px] h-[50px] text-[#9BE5FF] flex px-6 py-3 justify-center items-center border-2 border-white"
                    style={{
                      backgroundImage: `linear-gradient(to left, #2F1C8F, #923DC5)`,
                    }}
                  >
                    CLICK TO DOWNLOAD
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div> */}
        {/* <div className="w-full flex flex-col md:flex-row items-center justify-between border-2 border-white p-4 md:p-0 gap-4 md:gap-0">
          <div className="w-full md:w-[33%] flex justify-center items-center">
            <img src={bootsLogo} alt="logo" />
          </div>
          <div className="w-full md:w-[33%] flex flex-col justify-center items-center">
            <span
              className="text-2xl md:text-4xl subline-yellow-small"
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              EMAIL
            </span>
            <span
              className="text-white text-sm md:text-lg"
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              pussinboots@pussinboots.meme
            </span>
          </div>
          <div className="w-full md:w-[33%] flex flex-col justify-center items-center">
            <span
              className="text-2xl md:text-4xl subline-yellow-small"
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              FOLLOW US
            </span>
            <div className="flex flex-row items-center gap-2">
              <TwitterIcon width="14px" height="14px" />
              <TelegramIcon width="14px" height="14px" />
              <DexToolsIcon width="14px" height="14px" />
              <DexScreenerIcon width="14px" height="14px" />
              <BirdEyeIcon width="14px" height="14px" />
            </div>
          </div>
        </div> */}
        <div className="w-full flex p-6 md:p12 gap-4 md:gap-[] flex-col">
          <div className="w-full flex flex-row justify-center items-center subline">
            <span className="text-[30px]">
              Let’s make history together with Jordan Cats!
            </span>
          </div>
          <div className="w-full flex">
            <span
              className="text-sm md:text-lg text-white"
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              By following these guidelines and using the provided structure,
              you can create an engaging and informative website for Jordan Cats
              that will attract and retain users, while also fostering a strong
              community.
            </span>
          </div>
          <div className="w-[50%] flex flex-col gap-4">
            <div className="w-full flex gap-2 flex-col">
              <span className="text-white subline text-[24px]">
                🔹 Daily Rewards:
              </span>
              <span
                className="text-sm md:text-lg text-white"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                Double your coins every 24 hours by participating in our
                exciting NFT game.
              </span>
            </div>
            <div className="w-full flex gap-2 flex-col">
              <span className="text-white subline text-[24px]">
                📅 Daily NFT Challenge 📅
              </span>
              <span
                className="text-sm md:text-lg text-white"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                Every day brings a new chance to win! Participate in our daily
                NFT challenge and see if your Fashion Cat is the lucky match.
                It’s a fun and engaging way to earn more while being a part of
                our community.
              </span>
            </div>
            <div className="w-full flex gap-2 flex-col">
              <span className="text-white subline text-[24px]">🔹 Create:</span>
              <span
                className="text-sm md:text-lg text-white"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                Design a unique Fashion Cat NFT every day.
              </span>
            </div>
            <div className="w-full flex gap-2 flex-col">
              <span className="text-white subline text-[24px]">🔹 Share:</span>
              <span
                className="text-sm md:text-lg text-white"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                Post your creation and tag your friends.
              </span>
            </div>
            <div className="w-full flex gap-2 flex-col">
              <span className="text-white subline text-[24px]">🔹 Win:</span>
              <span
                className="text-sm md:text-lg text-white"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                Double your coins if your NFT matches ours.
              </span>
            </div>
          </div>
        </div>

        <div className="w-full md:w-[50%] flex flex-col gap-4 md:gap-[18px] self-end">
          {/* <div className="w-full flex flex-col md:flex-row justify-between items-center gap-2 md:gap-0">
            <img
              className="h-[40px] w-[40px] md:h-[80px] md:w-[80px]"
              src={starLogo}
              alt="star"
            />
            <div className="w-full md:w-[15%] h-1 bg-[#68C0FF]"></div>

            <span
              className="text-lg md:text-xl w-full md:w-[70%] text-[#68C0FF]"
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              Don’t miss out! Start creating and winning today.
            </span>
          </div> */}
          <div className="w-full flex items-center">
            <span
              className="subline-yellow-small text-3xl md:text-[42px]"
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              🌐 Join the Jordan Cats Revolution 🌐
            </span>
          </div>
          <div className="w-full gap-4">
            <div className="w-full flex gap-2 flex-col">
              <span className="text-white ">
                🔹 Early Access: Be the first to know about new features and
                updates.
              </span>

              <span className="text-white ">
                🔹 Exclusive Rewards: Participate in special events and earn
                rewards.
              </span>
              <span className="text-white ">
                🔹 Community Support: Get help and support from a friendly and
                active community.
              </span>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
