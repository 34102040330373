import lambo from "../../assets/generated-backgrounds/lambo.png";
import tesla from "../../assets/generated-backgrounds/tesla.png";
import ferrari from "../../assets/generated-backgrounds/ferrari.png";
import bentley from "../../assets/generated-backgrounds/bentley.png";

import firstCap from "../../assets/generated-caps/first-cap.png";
import secondCap from "../../assets/generated-caps/second-cap.png";
import thirdCap from "../../assets/generated-caps/third-cap.png";
import fourthCap from "../../assets/generated-caps/fourth-cap.png";

import firtsChain from "../../assets/generated-chains/first-chain.png";
import secondChain from "../../assets/generated-chains/second-chain.png";
import thirdChain from "../../assets/generated-chains/third-chain.png";
import fourthChain from "../../assets/generated-chains/fourth-chain.png";

import firtsShirt from "../../assets/generated-t-shirts/first-t-shirt.png";
import secontShirt from "../../assets/generated-t-shirts/second-t-shirt.png";
import thirdShirt from "../../assets/generated-t-shirts/third-t-shirt.png";
import fourthShirt from "../../assets/generated-t-shirts/fourth-shirt.png";

import firstEye from "../../assets/generated-eyes/first-eye.png";
import secondEye from "../../assets/generated-eyes/second-eye.png";
import thirdEye from "../../assets/generated-eyes/third-eye.png";
import fourthEye from "../../assets/generated-eyes/fourth-eye.png";
import firstBoots from "../../assets/generated-boots/first-boots.png";
import secondBoots from "../../assets/generated-boots/second-boots.png";
import thirdBoots from "../../assets/generated-boots/third-boots.png";
import fourthBoots from "../../assets/generated-boots/fourth-boots.png";

import firstShorts from "../../assets/generated-shorts/first-shorts.png";
import secondShorts from "../../assets/generated-shorts/second-shorts.png";
import thirdShorts from "../../assets/generated-shorts/third-shorts.png";
import fourthShorts from "../../assets/generated-shorts/fourth-shorts.png";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IState {
  backgrounds: string[];
  shirts: string[];
  caps: string[];
  chains: string[];
  eyes: string[];
  boots: string[];
  shorts: string[];
  currentIndexes: {
    backgrounds: number;
    shirts: number;
    caps: number;
    chains: number;
    eyes: number;
    boots: number;
    shorts: number;
  };
}

const backgroundsArray: string[] = [lambo, tesla, ferrari, bentley];

const shirtsArray: string[] = [
  firtsShirt,
  secontShirt,
  thirdShirt,
  fourthShirt,
];

const capsArray: string[] = [firstCap, secondCap, thirdCap, fourthCap];

const chainsArray: string[] = [
  secondChain,
  thirdChain,
  fourthChain,

  firtsChain,
];

const eyesArray: string[] = [firstEye, secondEye, thirdEye, fourthEye];

const bootsArray: string[] = [firstBoots, secondBoots, thirdBoots, fourthBoots];

const shortsArray: string[] = [
  firstShorts,
  secondShorts,
  thirdShorts,
  fourthShorts,
];

const initialState: IState = {
  backgrounds: backgroundsArray,
  shirts: shirtsArray,
  caps: capsArray,
  chains: chainsArray,
  eyes: eyesArray,
  boots: bootsArray,
  shorts: shortsArray,
  currentIndexes: {
    backgrounds: 0,
    shirts: 0,
    caps: 0,
    chains: 0,
    eyes: 0,
    boots: 0,
    shorts: 0,
  },
};

export const ImageSlice = createSlice({
  name: "ImageSlice",
  initialState,
  reducers: {
    nextItem: (
      state,
      action: PayloadAction<keyof IState["currentIndexes"]>
    ) => {
      const key = action.payload;
      const maxIndex = state[key].length - 1;
      if (state.currentIndexes[key] < maxIndex) {
        state.currentIndexes[key]++;
      } else {
        state.currentIndexes[key] = 0;
      }
    },
    previousItem: (
      state,
      action: PayloadAction<keyof IState["currentIndexes"]>
    ) => {
      const key = action.payload;
      if (state.currentIndexes[key] > 0) {
        state.currentIndexes[key]--;
      } else {
        state.currentIndexes[key] = state[key].length - 1;
      }
    },
    randomItem: (state) => {
      (
        ["shirts", "caps", "chains", "eyes", "boots", "shorts"] as Array<
          keyof IState["currentIndexes"]
        >
      ).forEach((key) => {
        state.currentIndexes[key] = Math.floor(
          Math.random() * state[key].length
        );
      });
    },
    resetItem: (state) => {
      (
        ["shirts", "caps", "chains", "eyes", "boots", "shorts"] as Array<
          keyof IState["currentIndexes"]
        >
      ).forEach((key) => {
        state.currentIndexes[key] = 0;
      });
    },
  },
});

const { reducer: imageReducer, actions } = ImageSlice;
export const { nextItem, previousItem, randomItem, resetItem } =
  ImageSlice.actions;

export { imageReducer };
