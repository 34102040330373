import { FifthBlockComponent } from "../components/fifth-block";
import { FirstBlockComponent } from "../components/first-block";
import { FourthBlockComponent } from "../components/fourth-block";
import { GeneratorComponent } from "../components/generator-component";
import { SecondBlockComponent } from "../components/second-block";
import { ThirdBlock } from "../components/third-block";

export const HomePage = () => {
  return (
    <>
      <FirstBlockComponent />
      <SecondBlockComponent />
      <ThirdBlock />
      <FourthBlockComponent />
      <GeneratorComponent />
      <FifthBlockComponent />
    </>
  );
};
