import { FC, useState } from "react";
import bootsLogo from "../../assets/logos/boots-logo.png";
import { MenuIcon } from "../icons/menu-icon";

interface Links {
  tag: string;
}

const linksArray: Links[] = [
  {
    tag: "DEXTOOLS",
  },
  {
    tag: "DEXSCREENER",
  },
  {
    tag: "RAYDIUM",
  },
  {
    tag: "CONTRACT",
  },
];

export const Header: FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <main className="w-full flex flex-row  p-1 justify-between items-center absolute top-0  px-9">
      <img className="w-[160px] h-[75px]" src={bootsLogo} alt="Logo" />
      <div className="hidden md:flex flex-row items-center justify-between w-[65%]">
        {linksArray.map((item, index) => (
          <button key={index} className=" cursor-pointer">
            <span
              className="text-amber-300 md:text-base sm:text-xs font-extrabold"
              style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.75)" }}
            >
              {item.tag}
            </span>
          </button>
        ))}
      </div>
      <button
        className="md:hidden w-10 h-10 flex items-center justify-center"
        onClick={toggleMenu}
      >
        <MenuIcon />
      </button>
      <button
        className="hidden md:block w-[140px] md:h-[60px] sm:w-[80px] sm:h-[40px] md:text-[16px] sm:text-[10px] text-[#9BE5FF] border-2 border-[#FEDB35]"
        style={{ background: "linear-gradient(to left, #432299, #8538BE)" }}
      >
        BUY NOW
      </button>
      {menuOpen && (
        <div className="absolute top-[75px] left-0 w-full bg-[#1D1B2B] shadow-md p-4 flex flex-col items-center md:hidden">
          {linksArray.map((item, index) => (
            <button key={index} className="w-full text-center">
              <span className="text-amber-300 md:text-lg  sm:text-xs">
                {item.tag}
              </span>
            </button>
          ))}
        </div>
      )}
    </main>
  );
};
