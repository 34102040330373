import { FC, ReactNode } from "react";

interface Props {
  text: string;
  icon: ReactNode;
  background: string;
}

export const MediaButton: FC<Props> = ({ text, icon, background }) => {
  return (
    <button
      className="w-[140px] h-[59px] p-4 flex justify-center items-center rounded-full border-2"
      style={{
        background: background,
        borderColor: "#CB6D18",
      }}
    >
      <div className="w-full flex flex-row items-center justify-between">
        <div>{icon}</div>
        <span className="text-white">{text}</span>
      </div>
    </button>
  );
};
