import { FC } from "react";

import catsImage from "../../assets/images/three-cats.png";
import backgroundImage from "../../assets/images/lambo-cat.png";
import { MilestoneMagicComponent } from "../milestone-magic";

interface Tokenomics {
  title: string;
  value: string;
}

const tokenomics: Tokenomics[] = [
  {
    title: "1 BILLION",
    value: "TOTAL SUPPLY",
  },
  {
    title: "90 MILLION",
    value: "PRE-MARKETING AITDROP",
  },
  {
    title: "810 MILLION",
    value: "LP",
  },
  {
    title: "100 MILLION",
    value: "NFT GENERATOR/DAILY REWARDS",
  },
  {
    title: "100 MILLION",
    value: "MARKETING & CEX LISTING",
  },
];

export const ThirdBlock: FC = () => {
  return (
    <main
      className="w-screen flex flex-col items-center"
      style={{ backgroundColor: "#0D1927" }}
    >
      <div className="w-full flex flex-col md:flex-row items-center justify-between p-6 md:p-14 gap-8 md:gap-0">
        <div className="w-full md:w-[45%] flex items-center justify-center">
          <img src={catsImage} alt="cats" className="max-w-full h-auto" />
        </div>
        <div className="w-full md:w-[50%] flex flex-col gap-4 md:gap-[28px]">
          {/* <div className="w-full flex flex-col md:flex-row justify-between items-center gap-2 md:gap-0">
            <span
              className="text-lg md:text-xl w-full md:w-[50%] text-[#68C0FF]"
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              Our Community Vision
            </span>
            <div className="w-full md:w-full h-1 bg-[#68C0FF]"></div>
          </div> */}
          <div className="w-full flex justify-end">
            <span
              className="text-2xl md:text-[50px] subline-yellow-medium"
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              About Jordan Cats
            </span>
          </div>
          <div className="w-full">
            <span
              className="text-sm md:text-base text-white"
              style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
            >
              At Jordan Cats, the community is at the heart of everything we do.
              We aim to create a vibrant and interactive ecosystem where members
              feel valued and involved. Your participation helps shape the
              future of this exciting project, and we are dedicated to ensuring
              that everyone has a voice. 🔹 Inclusivity: Open to everyone, from
              crypto enthusiasts to cat lovers. 🔹 Engagement: Regular community
              events, challenges, and rewards. 🔹 Growth: Join us in our journey
              to create the most dynamic community in the crypto world.
            </span>
          </div>
        </div>
      </div>
      <div
        className="w-full flex flex-col gap-[90px] "
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="w-full flex flex-col md:flex-row justify-between items-center p-6 md:p-14 gap-8 md:gap-0">
          <div className="w-full md:w-[50%] flex flex-col gap-4 md:gap-8">
            {/* <div className="w-full flex flex-col md:flex-row justify-between items-center gap-2 md:gap-0">
              <div className="w-full md:w-[48%] h-1 bg-[#68C0FF]"></div>
              <span
                className="text-lg md:text-xl w-full md:w-[50%] text-[#68C0FF]"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                The Heartbeat of Jordan Cats
              </span>
            </div> */}
            <div className="w-full">
              <span
                className="text-3xl md:text-[70px] subline-yellow"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                JORDAN CATS TOKENOMICS
              </span>
            </div>
            {/* <div className="w-full">
              <span
                className="text-sm md:text-base text-white"
                style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)" }}
              >
                Our tokenomics are designed to ensure stability and growth.
                Here’s how it breaks down:
              </span>
            </div> */}
          </div>
        </div>
        {/* <div className="w-full flex flex-col px-4 md:px-14 py-4 md:py-7 gap-4">
          {tokenomics.map((item, index) => (
            <div
              key={index}
              className={`w-full flex justify-center items-center py-3 border-b border-[#3A434E] ${
                index === 0 ? "border-t" : ""
              }`}
              style={
                index === 0
                  ? {
                      backgroundImage:
                        "linear-gradient(to left, #23143D, #2F2F33)",
                    }
                  : {}
              }
              onMouseEnter={(e) => {
                if (index !== 0) {
                  e.currentTarget.style.backgroundImage =
                    "linear-gradient(to right, #1A2444, #1C353D)";
                }
                e.currentTarget.classList.add("hover:border-[ ]");
              }}
              onMouseLeave={(e) => {
                if (index !== 0) {
                  e.currentTarget.style.backgroundImage = "";
                }
                e.currentTarget.classList.remove("hover:border-[#68C0FF]");
              }}
            >
              <div className="flex flex-col justify-between items-center">
                <span className="text-center text-[#FEDB35] text-xl md:text-2xl lg:text-[41px]">
                  {item.numbers}
                </span>
                <span className="text-center text-[#857A7E] text-sm md:text-base">
                  {item.value}
                </span>
              </div>
            </div>
          ))}
        </div> */}
        <div className="w-full flex flex-row  self-center  md:w-[70%]   ">
          <MilestoneMagicComponent
            headline="Complete the new token distribution"
            data={tokenomics}
            valueColor="white"
            titleColor="#FEFF35"
            isHeadlineStyling
            isWrapped
            headLineColor="#FEFF35"
          />
        </div>
      </div>
    </main>
  );
};
