import React, { FC } from "react";

interface Props {
  width?: string;
  height?: string;
  color?: string;
}

export const MenuIcon: FC<Props> = ({
  width = "24px",
  height = "24px",
  color = "white",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 48 48"
    >
      <path fill={color} d="M6 22H42V26H6zM6 10H42V14H6zM6 34H42V38H6z"></path>
    </svg>
  );
};
