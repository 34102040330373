import { FC } from "react";

interface Props {
  text: string;
  bottomColor: string;
  topColor: string;
  width: string;
  borderColor: string;
  onPress?: () => void;
}

export const GenerateButton: FC<Props> = ({
  text,
  bottomColor,
  topColor,
  width,
  borderColor,
  onPress,
}) => {
  return (
    <div
      style={{ width: width, borderColor: borderColor }}
      className={`${width} border-t-2 border-r-2 border-l-2 border-b-4 rounded-full `}
    >
      <button
        onClick={onPress}
        className="relative w-full py-2 px-12 rounded-full font-bold uppercase cursor-pointer overflow-hidden text-center"
      >
        <div
          className={`absolute top-0 left-0 w-full h-1/2 `}
          style={{ backgroundColor: topColor }}
        ></div>
        <div
          className={`absolute bottom-0 left-0 w-full h-1/2 `}
          style={{ backgroundColor: bottomColor }}
        ></div>
        <span className="relative z-10 text-white text-lg md:text-xl lg:text-2xl w-full h-full flex items-center justify-center">
          {text}
        </span>
      </button>
    </div>
  );
};
